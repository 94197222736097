<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" centered :title="title">
        <div class="mb-4 text-center">
            <div v-if="commentaireUtilisateur && commentaireUtilisateur.trim().length">
                <label><em>Commentaire utilisateur :</em></label>
                <b-row>
                    <b-col cols="12">
                        <div v-html="sanitize(commentaireUtilisateur)" class="ck-content text-left pl-2 pr-2"></div>
                    </b-col>
                </b-row>
            </div>
            <label v-b-tooltip.hover="'Ce commentaire sera transmis par mail à l\'utilisateur'">
                <em>Commentaire éventuel</em> &middot;
                <small>
                    <font-awesome-icon icon="question-circle"/>
                </small>
            </label>
            <balloon-editor v-model="commentaire"/>
        </div>

        <div class="text-center">
            <b-checkbox v-if="discordInput" v-model="notifyDiscord" switch class="mb-3">
                <em>Envoyer un message Discord</em>
            </b-checkbox>

            <b-button variant="primary" @click="confirm">
                <font-awesome-icon icon="check"/>
                Confirmer
            </b-button>
            <b-button variant="secondary" @click="hideModal">
                <font-awesome-icon icon="times"/>
                Annuler
            </b-button>
        </div>
    </b-modal>
</template>

<script>
    import modalMixin from '@/mixin/modalMixin';

    const BalloonEditor = () => import('@/components/BalloonEditor');

    import {sanitize} from 'dompurify';

    export default {
        name: "CommentValidationModal",
        components: {BalloonEditor},
        mixins: [modalMixin],
        props: {
            title: {
                type: String,
                required: true
            },
            commentaireUtilisateur: {
                required: false,
                default: null
            },
            callback: {
                type: Function,
                required: true
            },
            discordInput: {
                type: Boolean,
                default: true
            }
        },
        data: () => ({
            commentaire: null,
            notifyDiscord: true,
            modalRef: 'commentValidation'
        }),
        methods: {
            sanitize,
            confirm() {
                this.hideModal();
                this.callback(this.commentaire, this.notifyDiscord);
            }
        }
    }
</script>
